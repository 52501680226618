import { Button, Center } from "@mantine/core";
import Translate from "Components/Translate";
import React from "react";

const UpdateBtn = ({ title, path }) => {
  const handleUpdate = () => {};
  return (
    <Center>
      <Button color="teal" onClick={handleUpdate}>
        <Translate>{title}</Translate>
      </Button>
    </Center>
  );
};

export default UpdateBtn;
