import { Group } from "@mantine/core";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";
import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";
import {
  CLIENT,
  CREATED_AT,
  DRIVER,
  LINK,
  LOCATION,
  TAXI,
  TAXIMETER,
} from "Constant";

export const API = {
  list: "/back/taximeters/alerts",
};

// Columns
export const COLUMNS = [
  {
    header: CLIENT,
    size: 300,

    Cell: ({ row }) => {
      const { client, code_client } = row.original;
      const path = `/client/${code_client}`;

      return <ReadSheetBtn read={path} title={client} />;
    },
  },
  {
    header: TAXI,
    size: 300,

    Cell: ({ row }) => {
      const { taxi, code_taxi } = row.original;
      const path = `/taxi/${code_taxi}`;

      return <ReadSheetBtn read={path} title={taxi} />;
    },
  },

  {
    header: TAXIMETER,
    size: 300,

    Cell: ({ row }) => {
      const { taximeter, code_taximeter } = row.original;
      const path = `/taximeter/${code_taximeter}`;

      return <ReadSheetBtn read={path} title={taximeter} />;
    },
  },
  {
    name: DRIVER,
    selector: "driver",
  },
  {
    name: LOCATION,
    selector: "location",
  },
  {
    name: CREATED_AT,
    selector: "created_at",
  },

  {
    header: LINK,
    id: "actions",
    button: "true",
    Cell: ({ row }) => {
      const { link } = row.original;
      return (
        <Group gap="xs">
          <ReadPageBtn read={link} />
        </Group>
      );
    },
  },
];
