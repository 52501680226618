import { Fragment, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";

import useFetchData from "hooks/useFetchDataWithDep";
import CustomActions from "./CustomActions";
import RefreshBtn from "./RefreshBtn";
import { Center, Group } from "@mantine/core";
import EmptyData from "Components/EmptyData";
import Translate from "Components/Translate";
import { THERE_IS_NO_DATA_FOUND } from "Constant";

const DataTable = ({
  newColumns,
  link,
  createBtn,
  globalSearch,
  columnSearch,
  renderDetailPanel,
}) => {
  const { refresh } = useSelector((state) => state.searchLink);
  const { t } = useTranslation();
  const columns = transferColumns(newColumns, t);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);

  const url = useMemo(() => {
    const params = new URLSearchParams();
    params.append("size", pagination.pageSize);
    params.append("index", pagination.pageIndex + 1);

    if (globalFilter) {
      params.append("search", globalFilter);
    }

    columnFilters.forEach((filter) => {
      if (filter.value) {
        params.append(filter.id, filter.value);
      }
    });

    sorting.forEach((filter) => {
      const val = filter.desc ? "DESC" : "ASC";
      params.append("sort_order", val);
      params.append("sort_column", filter.id);
    });

    const result = `${link}?${params.toString()}`;

    return result;
  }, [
    pagination.pageSize,
    pagination.pageIndex,
    globalFilter,
    columnFilters,
    sorting,
  ]);

  const { data, isLoading, isError } = useFetchData(url, url, refresh);

  const { total: paginationTotalRows, rows: listOfData } = getRows(data);

  const onGlobalFilterChange = (event) => {
    setGlobalFilter(event);
  };

  const table = useMantineReactTable({
    columns: columns,
    data: listOfData,
    rowCount: paginationTotalRows,
    initialState: { pagination: pagination },
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    manualPagination: true,
    mantinePaginationProps: {
      showRowsPerPage: true,
    },
    enableStickyHeader: true,
    mantineTableProps: {
      highlightOnHover: true,
      striped: "odd",
      withColumnBorders: true,
      withRowBorders: true,
      withTableBorder: true,
    },
    enableColumnResizing: true,
    enableColumnOrdering: true,
    // layoutMode: "grid",
    layoutMode: "flex",
    paginationDisplayMode: "pages",
    renderDetailPanel: renderDetailPanel,
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <Group gap="xs">
          {createBtn === undefined ? (
            <CustomActions table={table} />
          ) : (
            createBtn
          )}
          <RefreshBtn />
        </Group>
      );
    },

    // ----  SEARCH

    onGlobalFilterChange: onGlobalFilterChange,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    // enableGlobalFilter: globalSearch === undefined ? true : globalSearch,
    // enableColumnFilters: columnSearch === undefined ? true : columnSearch,
    enableGlobalFilter: true,
    enableColumnFilters: true,

    // ----  SEARCH

    renderEmptyRowsFallback: () => (
      <Center inline style={{ margin: "2%" }}>
        <Translate>{THERE_IS_NO_DATA_FOUND}</Translate>
      </Center>
    ),

    state: {
      isLoading: isLoading,
      showAlertBanner: false,
      showProgressBars: false,
      pagination,
      sorting,
    },
  });

  return <MantineReactTable table={table} />;
};

export default DataTable;

const transferColumns = (list, t) => {
  return list.map((col, i) => {
    if (col.name && col.selector)
      return {
        ...col,
        accessorKey: col.selector,
        header: t(col.name),
        enableColumnFilter: true,
      };
    else
      return {
        ...col,
        enableColumnFilter: true,
        header: t(col.header),
        enableSorting: true,
      };
  });
};

const getRows = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return { total: 1, rows: [] };
  }
};
