import React from "react";
import { Button } from "@mantine/core";

import Translate from "Components/Translate";

import patchItem from "Services/patchItem";

import {
  CANCEL,
  CONFIRM,
  CREATE_DELIVERY_FORM,
  PREPARE,
  READY,
} from "Constant";

export const ConfirmPaymentBtn = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="green"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{CONFIRM}</Translate>
    </Button>
  );
};

export const CreateDeliveryFormBtn = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="orange"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{CREATE_DELIVERY_FORM}</Translate>
    </Button>
  );
};

export const PrepareDeliveryFormBtn = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="orange"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{PREPARE}</Translate>
    </Button>
  );
};

export const ReadyDeliveryFormBtn = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="orange"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{READY}</Translate>
    </Button>
  );
};
