import { CATEGORY, LABEL, PRICE, UPDATED_AT } from "Constant";

import { getCodeLabel } from "helper/getOptions";

// Urls
export const API = {
  list: "/back/models/prices",
  create: "/back/brand/taxiora/model",
};

// Columns
export const COLUMNS = [
  { name: CATEGORY, selector: "model_category" },
  { name: LABEL, selector: "model_label" },
  { name: PRICE, selector: "price" },
  { name: UPDATED_AT, selector: "updated_at" },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Label",
    placeholder: "label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: CATEGORY,
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/categories",
    getOptions: getCodeLabel,
  },
];

// label
// category

export const HISTORY_COLUMNS = [
  { name: "Price", selector: "price" },
  { name: "Date begin", selector: "date_begin" },
  { name: "Date end", selector: "date_end" },
  { name: "Created at", selector: "created_at" },
];
// Urls
export const HISTORY_API = {
  list: (code) => `/back/model/${code}/prices`,
  create: (code) => `/back/model/${code}/price`,
};

// Form
export const HISTORY_CREATE_ITEMS = [
  {
    title: "Price",
    placeholder: "price",
    name: "price",
    inputForm: "SimpleInput",
  },
  {
    title: "TVA %",
    placeholder: "7%",
    name: "vat",
    inputForm: "SimpleInput",
  },
  {
    title: "Date Begin",
    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
  {
    title: "Date End",
    placeholder: "date_end",
    name: "date_end",
    inputForm: "DateInput",
    required: false,
  },
];
