import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";

import { getFullNameCode } from "helper/getOptions";

import { Group } from "@mantine/core";
import { SettingsBtn } from "./SettingsBtn";

// Urls
export const API = {
  list: "/back/workOrders",
  create: "/back/workOrder",
  get: "/back/workOrder/",
  edit: "/back/workOrder",
  delete: "/back/workOrder/",
  changeStatus: (code, newStatus) => `/back/workOrder/${code}/${newStatus}`,
};

// Columns
export const COLUMNS = [
  { name: "Creator", selector: "creator" },
  { name: "Executor", selector: "executor" },
  { name: "Status", selector: "status_label" },
  { name: "Created at", selector: "created_at" },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 80,
    Cell: ({ row }) => {
      const { code, creator } = row.original;
      return (
        <Group gap="xs">
          <ReadPageBtn read={`/work-order/${code}`} />
          <EditBtn read={API.get + code} extra={creator} />
          <SettingsBtn data={row.original} />
        </Group>
      );
    },
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Status",
    name: "status",
    inputForm: "SelectInput",
    options: [
      { value: "canceled", label: "Canceled" },
      { value: "draft", label: "Draft" },
      { value: "confirmed", label: "Confirmed" },
      { value: "in_preparation", label: "In Preparation" },
      { value: "ready", label: "Ready" },
      { value: "closed", label: "Closed" },
    ],
  },
];
// Form

export const CREATE_ITEMS = [
  {
    title: "Executor",
    name: "executor",
    inputForm: "SelectApiInput",
    optionsApi: "/back/executors",
    getOptions: getFullNameCode,
  },
];
export const EDIT_ITEMS = [
  {
    title: "Executor",
    name: "executor",
    inputForm: "SelectApiInput",
    optionsApi: "/back/executors",
    getOptions: getFullNameCode,
  },
  {
    title: "Description",
    placeholder: "Description",
    name: "description",
    inputForm: "TextareaInput",
    required: false,
  },
];
