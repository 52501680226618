import { Card, CardBody, Col, Row } from "reactstrap";
import React, { Fragment, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import logoSmarteo from "assets/images/logo/smarteo.jpeg";

import useFetchData from "hooks/useFetchDataWithDep";

import StaticTextTable from "Components/StaticData/StaticTextTable";
import Breadcrumbs from "Components/Breadcrumbs";
import Loading from "Components/Loading/Loading";
import Translate from "Components/Translate";
import NotFound from "Components/NotFound";
import PrintBtn from "Components/PrintBtn";

import {
  TOTAL,
  TOTAL_PRICE_TND,
  TOTAL_PRICE_WITH_VAT,
  TOTAL_VAT_AMOUNT_TND,
  EDIT,
  CONFIRM,
} from "Constant";

import { Button } from "primereact/button";

import patchItem from "Services/patchItem";

import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import {
  testStatus,
  getRowRes,
  columnsProd,
  columnsServ,
  estimateDetailsColumns,
} from "./data";
import StaticTab from "./StaticTab";
import TimeScalComp from "./TimeScalComp";
import CancelEstimate from "./CancelEstimate";
import styles from "./estimate.module.css";

const ReadEstimatePage = () => {
  const navigate = useNavigate();
  const { refresh } = useSelector((state) => state.searchLink);

  const { code } = useParams();
  const componentRef = useRef();
  const urlCancel = `/back/estimate/${code}/cancel`;
  const urlConfirm = `/back/estimate/${code}/confirm`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const url = `/back/estimate/${code}`;
  const {
    data: estimatesData,
    isLoading,
    isError,
  } = useFetchData(url, refresh);

  let estimateData;
  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    estimateData = getRowRes(estimatesData);
  }

  const {
    status,

    completeName,
    estimateNumber,
    taxi_info,
    createdAt,

    company,
    city,
    zipCode,
    address,
  } = estimateData;

  console.log(estimateData);

  const titleEstimate = `DEVIS n° ${estimateNumber}`;
  // const titleEstimate = `DEVIS n° ${estimateNumber}`;

  const totalData = [
    { label: TOTAL_PRICE_TND, value: estimateData.price },
    { label: TOTAL_VAT_AMOUNT_TND, value: estimateData.vatAmount },
    { label: TOTAL_PRICE_WITH_VAT, value: estimateData.priceVat },
    // { label: VAT_PERCENTAGE, value: estimateData.vat },
  ];
  const showProd = estimateData.products.length ? true : false;
  const showService = estimateData.services.length ? true : false;
  const breadCrumbsProps = [`DEVIS n° ${estimateNumber} | ${status}`];

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <div ref={componentRef}>
            <Row style={{ marginLeft: "50px", marginTop: "50px" }}>
              <Col className="col-7">
                <div style={{ marginLeft: "20px" }}>
                  <img src={logoSmarteo} className={styles["logo"]} alt="" />
                  <div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      {`${city} ${zipCode}`}
                    </div>
                    <div>{address}</div>
                    <div>{company}</div>
                  </div>
                </div>
              </Col>
              <Col className="col-5">
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginBottom: "10px",
                    }}
                  >
                    {titleEstimate}
                  </div>
                  <div>{completeName}</div>
                  <div>{taxi_info}</div>
                  <div>{createdAt}</div>
                </div>
              </Col>
            </Row>

            <div>
              <div style={{ margin: "40px" }}>
                {showProd && (
                  <Fragment>
                    <div
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      Produits
                    </div>
                    <StaticTab
                      newColumns={columnsProd}
                      newRows={estimateData.products}
                    />
                  </Fragment>
                )}
                {showService && (
                  <Fragment>
                    <div style={{ fontSize: "16px", marginTop: "10px" }}>
                      Services
                    </div>

                    <StaticTab
                      newColumns={columnsServ}
                      newRows={estimateData.services}
                    />
                  </Fragment>
                )}

                <div>
                  <div style={{ fontSize: "16px", marginTop: "10px" }}>
                    Validité du devis 1 mois
                  </div>
                  <StaticTab
                    newColumns={estimateDetailsColumns}
                    newRows={[estimateData]}
                  />
                  <TimeScalComp data={estimateData} />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StaticTextTable requiredData={totalData} title={TOTAL} />
            </div>
          </div>

          <div className={styles["print-btn"]}>
            <PrintBtn onClick={handlePrint} />
          </div>
          <div className={styles["print-btn"]}>
            {/* --- */}

            <Button
              label={<Translate>{CONFIRM}</Translate>}
              icon="pi pi-check"
              severity="warning"
              onClick={() => patchItem(urlConfirm)}
              disabled={!testStatus(status)}
            />
            {/* -- */}

            <Button
              label={<Translate>{EDIT}</Translate>}
              icon="pi pi-pencil"
              severity="info"
              onClick={() => navigate(`/edit-estimate/${code}`)}
              disabled={!testStatus(status)}
            />
            <CancelEstimate
              url={urlCancel}
              disabled={!testStatus(status)}
              estimateNumber={estimateNumber}
            />
            {/* --- */}
            {/* <Button
              label={<Translate>{CANCEL}</Translate>}
              icon="pi pi-trash"
              severity="danger"
              onClick={() => navigate(`/edit-estimate/${code}`)}
              disabled={!testStatus()}
            /> */}
            {/* --- */}
          </div>
        </CardBody>
      </Card>

      <Helmet>
        <title>Devis {estimateData.estimateNumber}</title>
      </Helmet>
    </Fragment>
  );
};

export default ReadEstimatePage;
