import React, { Fragment } from "react";
import { useDispatch } from "react-redux";

import { ActionIcon, Menu, rem, Tooltip } from "@mantine/core";

import Translate from "Components/Translate";

import { CANCEL, CLOSE, CONFIRM, PREPARE, SETTINGS } from "Constant";

import patchItem from "Services/patchItem";

import {
  IconDots,
  IconCheckbox,
  IconChartArrows,
  IconInfinityOff,
  IconTrash,
} from "@tabler/icons-react";
import deleteItem from "Services/deleteItem";

export const SettingsBtn = ({ data }) => {
  const { code, to_cancel, to_confirm, to_prepare, to_finish, to_close } = data;

  const handleClick = (param) => {
    const url = `/back/workOrder/${code}/${param}`;
    patchItem(url);
  };

  const handleCancel = () => {
    const url = `/back/workOrder/${code}`;
    deleteItem(url);
  };

  return (
    <Fragment>
      <ActionIcon size="lg" variant="subtle" color="gray">
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Tooltip label={<Translate>{SETTINGS}</Translate>}>
              <IconDots color="gray" />
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              leftSection={
                <IconCheckbox style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => handleClick("confirm")}
              disabled={!to_confirm}
            >
              <Translate>{CONFIRM}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconChartArrows style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => handleClick("prepare")}
              disabled={!to_prepare}
            >
              <Translate>{PREPARE}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconInfinityOff style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => handleClick("close")}
              disabled={!to_close}
            >
              <Translate>{CLOSE}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconTrash style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => handleCancel("close")}
              disabled={!to_cancel}
            >
              <Translate>{CANCEL}</Translate>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </ActionIcon>
    </Fragment>
  );
};
