import Translate from "Components/Translate";
import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

function PanelApp({ title, children }) {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="1">
          <Translate>{title}</Translate>
        </AccordionHeader>
        <AccordionBody accordionId="1">{children}</AccordionBody>
      </AccordionItem>
    </Accordion>
  );
}

export default PanelApp;
