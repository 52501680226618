import { Group } from "@mantine/core";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";
import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";
import {
  ACTIONS,
  BRAND,
  CIRCULATION_DATE,
  CLIENT,
  DRIVERS,
  LICENCE,
  LICENCE_EXPIRE_AT,
  LICENCE_GOT_AT,
  MODEL,
  PLACES,
  REGISTRATION_NUMBER,
  TAXIMETER,
} from "Constant";

export const API = {
  list: "/back/taxis",
};

export const COLUMNS = [
  {
    header: CLIENT,
    size: 300,

    Cell: ({ row }) => {
      const { name_client, code_client } = row.original;
      const path = `/client/${code_client}`;

      return <ReadSheetBtn read={path} title={name_client} />;
    },
  },
  //   count_drivers
  {
    name: TAXIMETER,
    selector: "taximeter",
  },

  {
    name: BRAND,
    selector: "brand",
  },
  {
    name: MODEL,
    selector: "model",
  },
  // {
  //   name: PLACES,
  //   selector: "places",
  // },
  {
    name: DRIVERS,
    selector: "count_drivers",
  },

  {
    name: LICENCE,
    selector: "licence",
  },
  {
    name: REGISTRATION_NUMBER,
    selector: "registration_number",
  },

  {
    name: LICENCE_GOT_AT,
    selector: "licence_got_at",
  },
  {
    name: LICENCE_EXPIRE_AT,
    selector: "licence_expire_at",
  },
  {
    name: CIRCULATION_DATE,
    selector: "circulation_date",
  },
  {
    header: ACTIONS,
    id: "actions",
    button: "true",
    size: 80,
    Cell: ({ row }) => {
      const { code } = row.original;
      return (
        <Group gap="xs">
          <ReadPageBtn read={`/taxi/${code}`} />
        </Group>
      );
    },
  },
];
