import { changedAlert } from "../Components/Alerts/Alerts";

import { toggleRefresh } from "../redux/searchLink";
import { store } from "../redux/store";

import axiosClient from "./axiosClient";

import errorMsg from "./errorMsg";

const patchItemForm = (url, data) => {
  axiosClient
    .patch(url, data)
    .then(() => {
      changedAlert();
      store.dispatch(toggleRefresh());
    })
    .catch((error) => {
      console.error("Request failed:", error);
      errorMsg(error);
    });
};
export default patchItemForm;
