import { DEPOSIT, TAXI } from "Constant";
import {
  getCodeLabel,
  getModelsLabel,
  getServiceLabel,
} from "helper/getOptions";

export const clientInp = {
  title: "Client",
  name: "client",
  optionsApi: "/back/client",
  getOptions: getCodeLabel,
  required: false,
  width: "4",
};

export const taxiInp = (taxi) => ({
  title: TAXI,
  name: "taxi",
  optionsApi: `/back/client/${taxi}/taxis/choices`,
  getOptions: getCodeLabel,
  required: false,
  width: "4",
});

export const depositInp = {
  title: DEPOSIT,
  name: "deposit",
};

export const categoryInp = {
  title: "Category",
  name: "category",
  optionsApi: "/back/inventory-categories",
  getOptions: getCodeLabel,
};

export const modelInp = (category) => ({
  title: "Model",
  name: "model",
  optionsApi: `/back/models/choices/${category}`,
  getOptions: getModelsLabel,
});

export const serviceInp = {
  title: "Service",
  name: "service",
  inputForm: "SelectApiInput",
  optionsApi: "/back/services/choices",
  getOptions: getCodeLabel,
};

export const getNumber = (param) => {
  if (isNaN(param)) return 0;

  return parseFloat(param).toFixed(1);
};
