import { PRODUCTS, SERVICES } from "Constant";
import CancelBtn from "../../Components/DataTableBtns/CancelBtn";
import ChangeStatusPurchaseRequests from "../../Components/DataTableBtns/ChangeStatusPurchaseRequests";
import { getCodeLabel, getOptionNumber } from "../../helper/getOptions";

// Urls
export const API = {
  list: "/back/purchaserequests",
  create: "/back/rawmaterialpr",
  delete: "/back/rawmaterialpr/",
  finish: (code) => `/back/rawmaterialpr/${code}/finish`,
  validate: (code) => `/back/rawmaterialpr/${code}/validate`,
};

// Columns
export const COLUMNS = [
  { name: "Number", selector: "number" },
  { name: "Category", selector: "category" },
  { name: "Quantity", selector: "quantity" },
  { name: "Model", selector: "model" },
  { name: "Created at", selector: "created_at" },

  { name: "Status", selector: "status" },

  {
    name: "State",
    cell: (row) => <ChangeStatusPurchaseRequests row={row} />,
    button: "true",
  },

  {
    name: "Cancel",
    cell: (row) => (
      <CancelBtn
        cancel={`/back/rawmaterialpr/${row.code}`}
        isDisable={!row.to_delete}
      />
    ),
    button: "true",
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Model",
    placeholder: "Model",
    name: "model",
    inputForm: "SimpleInput",
  },
  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/rawmaterial/categories",
    getOptions: getCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Quantity",
    name: "quantity",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 100),
    number: true,
  },
  {
    title: "Supplier",
    name: "Supplier",
    inputForm: "SimpleInput",
  },
  {
    title: "Category",
    name: "category",
    inputForm: "MultiSelectApiInput",
    optionsApi: "/back/rawmaterial/categories",
    getOptions: getCodeLabel,
    secondSelect: (param) => {
      return {
        title: "Model",
        name: "model",
        optionsApi: `/back/rawmaterial/${param}/models`,
        getOptions: getCodeLabel,
      };
    },
  },
];

export const EDIT_ITEMS = [
  {
    title: "Label",

    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/rawmaterial/categories",
    getOptions: getCodeLabel,
  },
];
