import React, { Fragment } from "react";

import DataTables from "Components/DataTables/DataTable";

import { HISTORY_API, HISTORY_COLUMNS, HISTORY_CREATE_ITEMS } from "./data";
import { Group } from "@mantine/core";
import CustomActions from "Components/DataTables/CustomActions";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItemWithZod";

const RenderDetailPanel = ({ data }) => {
  const { code, label } = data.original;

  const list = HISTORY_API.list(code);
  const create = HISTORY_API.create(code);
  const createModalTitle = `Ajouter un nouveau prix à ${label}`;

  return (
    <Fragment>
      <Group justify="center" grow>
        <DataTables
          newColumns={HISTORY_COLUMNS}
          link={list}
          createBtn={<CustomActions modalType={"createPrice"} />}
        />
      </Group>

      <ModalApp type="createPrice" title={createModalTitle}>
        <AddItem addURL={create} items={HISTORY_CREATE_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default RenderDetailPanel;
