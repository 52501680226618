import { useState, Fragment } from "react";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";

import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { toggleRefresh } from "../../redux/searchLink";
import { toggleModal } from "../../redux/modal";

import CancelModalBtn from "Components/ModalApp/CancelModalBtn";
import FilterInput from "Components/InputsWithZod";
import Loading from "Components/Loading/Loading";

import useFetchData from "hooks/useFetchData";

import editData from "./editData";

const EditItem = ({ itemsUpdate, defVal, getOldData, newVal }) => {
  const defInp = defVal ? defVal : {};
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { getLink, putLink } = useSelector((state) => state.currentRow);

  const schema = getSchema(itemsUpdate);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const addedWithSuccess = () => {
    dispatch(toggleRefresh());
    dispatch(toggleModal());
  };

  const submitData = (data) => {
    setIsLoading(true);
    const axiosUrl = putLink || getLink;

    const postData = newVal ? { ...newVal, ...data } : data;

    editData(postData, axiosUrl, setIsLoading, addedWithSuccess);
  };

  const { data, isLoading: showSpinner, isError } = useFetchData(getLink);

  if (showSpinner) return <Loading />;
  else {
    const oldData = getOldData ? getOldData(data) : defaultGetOldData(data);
    return (
      <Fragment>
        <form onSubmit={handleSubmit(submitData)}>
          <div className="flex flex-wrap">
            {itemsUpdate.map((elem, i) => (
              <Fragment key={i}>
                <FilterInput
                  control={control}
                  item={elem}
                  handleChange={register}
                  errors={errors}
                  oldValue={oldData ? oldData[elem.name] : null}
                />
              </Fragment>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1%",
              padding: "5px",
            }}
          >
            <CancelModalBtn />
            <Button
              type="submit"
              label="Go"
              severity="success"
              loading={isLoading}
              onClick={handleSubmit(submitData)}
            />
          </div>
        </form>
      </Fragment>
    );
  }
};

export default EditItem;

const getSchema = (param) => {
  const result = {};
  const schema = z.string().min(1, "Required");

  param.forEach((element) => {
    const isReq = element.required === undefined ? true : false;
    if (element.schema) {
      result[element.name] = element.schema;
    } else if (element.inputForm === "CheckBox") {
      result[element.name] = z.boolean();
    } else {
      if (isReq) result[element.name] = schema;
      else result[element.name] = z.string().optional();
    }
  });

  return z.object(result);
};

const defaultGetOldData = (data) => {
  try {
    return data.data.results.data;
  } catch (error) {
    return [];
  }
};
