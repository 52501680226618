import React from "react";
import ClientInfo from "pages/ReadClientPage.test/ClientInfo";
import { isEmpty } from "./isEmpty";

const ClientComp = ({ data }) => {
  if (isEmpty(data)) return isEmpty(data);
  return <ClientInfo data={data} />;
};

export default ClientComp;
