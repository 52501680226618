import DriversInfo from "pages/ReadClientPage.test/DriversInfo";
import React from "react";
import { isEmpty } from "./isEmpty";

const UsersComp = ({ data }) => {
  if (isEmpty(data)) return isEmpty(data);
  return <DriversInfo data={data} />;
};

export default UsersComp;
