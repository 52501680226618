import React, { useEffect, useState } from "react";

const OneTest = () => {
  const [state, setState] = useState("");

  useEffect(() => {
    return () => {
      setState("");
    };
  }, ["state"]);

  return (
    <div>
      <input
        type="text"
        onChange={(e) => {
          setState(e.target.value);
        }}
      />
      <h1>{state}</h1>
    </div>
  );
};

export default OneTest;
