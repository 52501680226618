import * as React from "react";
import OneTest from "./OneTest";
import { useState, Fragment } from "react";
import { v4 as uuidv4 } from "uuid"; // Importing uuid for unique ID generation

export default function Tests() {
  const [state, setState] = useState([{ id: uuidv4() }]); // Initialize state with one element that has a unique ID

  const addTest = () => {
    setState((prevState) => [...prevState, { id: uuidv4() }]); // Add a new element with a unique ID
  };

  const deleteTest = (id) => {
    setState((prevState) => prevState.filter((elem) => elem.id !== id)); // Remove the element with the matching ID
  };

  return (
    <>
      {state.map((elem) => (
        <Fragment key={elem.id}>
          <OneTest />
          <button onClick={() => deleteTest(elem.id)}>
            <h2>Delete One</h2>
          </button>
        </Fragment>
      ))}

      <button onClick={addTest}>
        <h2>Add One</h2>
      </button>
    </>
  );
}
