import EmptyData from "Components/EmptyData";

export const isEmpty = (data) => {
  try {
    if (Array.isArray(data))
      if (data.length > 0) return false;
      else return <EmptyData />;

    if (typeof data === "object" && data !== null) return false;

    return <EmptyData />;
  } catch {
    return <EmptyData />;
  }
};
