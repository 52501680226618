import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { Paper, Text, Timeline } from "@mantine/core";

import { IconCar, IconUser, IconUsersGroup } from "@tabler/icons-react";

import useFetchData from "hooks/useFetchDataWithDep";

import { DRIVERS, GENERAL_DATA, TAXIS } from "Constant";

import Breadcrumbs from "Components/Breadcrumbs/SheetOf";
import Loading from "Components/Loading/Loading";
import Translate from "Components/Translate";

import DriversInfo from "./DriversInfo";
import ClientInfo from "./ClientInfo";
import TaxisInfo from "./TaxisInfo";

import { useSelector } from "react-redux";
import DataNotFound from "Components/NotFound/DataNotFound";
import { Helmet } from "react-helmet";
import FormPupUp from "./FormPupUp";

const getRowsFromRes = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return [];
  }
};

const ReadClientPage = () => {
  const { code } = useParams();
  const { refresh } = useSelector((state) => state.searchLink);

  const url = `/back/client/${code}/sheet`;
  const { data, isLoading, isError } = useFetchData(url, refresh);

  const clientSheet = getRowsFromRes(data);

  if (isLoading) return <Loading />;

  if (isError) return null;

  const { client, users: drivers, taxis } = clientSheet;

  const getClientName = () => {
    try {
      const { firstname, lastname } = clientSheet.client;
      return `${firstname} ${lastname}`;
    } catch {
      return "";
    }
  };

  const stepsList = [
    {
      icon: <IconUser size={30} />,
      title: GENERAL_DATA,
      content: <ClientInfo data={client} />,
    },
    {
      icon: <IconUsersGroup size={30} />,
      title: DRIVERS,
      content: <DriversInfo data={drivers} />,
    },
    {
      icon: <IconCar size={30} />,
      title: TAXIS,
      content: <TaxisInfo data={taxis} />,
    },
  ];

  const clientName = getClientName();

  const breadCrumbsProps = [clientName];

  if (Object.keys(clientSheet).length === 0) return <DataNotFound />;

  return (
    <Fragment>
      <Helmet>
        <title>{clientName}</title>
      </Helmet>

      <Paper shadow="xs" withBorder p="xl">
        <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

        <Timeline color="orange" active={stepsList.length} bulletSize={40}>
          {stepsList.map((elem, i) => {
            const title = (
              <Text fw={700} size="lg">
                <Translate>{elem.title}</Translate>
              </Text>
            );

            return (
              <Timeline.Item bullet={elem.icon} title={title} key={i}>
                {elem.content}
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Paper>

      <FormPupUp clientCode={code} />
    </Fragment>
  );
};

export default ReadClientPage;
