import { LABEL, PRICE, TO_TAXIMETER, UPDATED_AT } from "Constant";
import HandleResult from "helper/HandleResult";

// Urls
export const API = {
  list: "/back/services/prices",
  create: "/back/service",
};

// Columns
export const COLUMNS = [
  { name: LABEL, selector: "label" },
  { name: PRICE, selector: "price" },
  { name: UPDATED_AT, selector: "updated_at" },

  {
    header: TO_TAXIMETER,
    id: TO_TAXIMETER,
    button: "true",
    size: 180,
    Cell: ({ row }) => {
      const { to_taximeter } = row.original;
      return <HandleResult data={to_taximeter} />;
    },
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Code",
    placeholder: "Code",
    name: "code",
    inputForm: "SimpleInput",
  },
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: TO_TAXIMETER,
    name: "required",
    inputForm: "SelectInput",
    options: [
      {
        value: "true",
        label: "required",
        name: "required",
      },
      {
        value: "false",
        label: "not required",
        name: "required",
      },
    ],
  },
];

// Urls
export const HISTORY_API = {
  list: (code) => `/back/service/${code}/prices`,
  create: (code) => `/back/service/${code}/price`,
};

// Columns
export const HISTORY_COLUMNS = [
  { name: "Price", selector: "price" },
  { name: "Date begin", selector: "date_begin" },
  { name: "Date end", selector: "date_end" },
  { name: "Created at", selector: "created_at" },
];

// Form
export const HISTORY_CREATE_ITEMS = [
  {
    title: "Price",
    placeholder: "price",
    name: "price",
    inputForm: "SimpleInput",
  },
  {
    title: "TVA %",
    placeholder: "7%",
    name: "vat",
    inputForm: "SimpleInput",
  },
  {
    title: "Date Begin",
    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
  {
    title: "Date End",
    placeholder: "date_end",
    name: "date_end",
    inputForm: "DateInput",
    required: false,
  },
];
